import React from "react";

const Email = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_144_90)">
        <path
          d="M18.3334 6.2793V14.1668C18.3335 14.8045 18.0898 15.4181 17.6523 15.882C17.2149 16.346 16.6167 16.6252 15.9801 16.6626L15.8334 16.6668H4.16675C3.52907 16.6668 2.91549 16.4232 2.45153 15.9857C1.98758 15.5483 1.70833 14.95 1.67091 14.3135L1.66675 14.1668V6.2793L9.53758 11.5268L9.63425 11.5818C9.74817 11.6375 9.87329 11.6664 10.0001 11.6664C10.1269 11.6664 10.252 11.6375 10.3659 11.5818L10.4626 11.5268L18.3334 6.2793Z"
         
        />
        <path
          d="M15.8334 3.3335C16.7334 3.3335 17.5226 3.8085 17.9626 4.52266L10.0001 9.831L2.0376 4.52266C2.24654 4.1833 2.53361 3.89883 2.87485 3.69298C3.2161 3.48713 3.60162 3.36588 3.99926 3.33933L4.16676 3.3335H15.8334Z"
         
        />
      </g>
      <defs>
        <clipPath id="clip0_144_90">
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Email;
